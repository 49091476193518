<template>
  <div>
    <v-container>
      <page-title title="CPAR Source">
        <template slot="action">
          <v-btn @click="saveData()" color="primary">Save</v-btn>
          <v-btn @click="goBack()" class="mx-1">Back</v-btn>
        </template>
      </page-title>

      <v-form ref="form">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col lg="6">
                    <v-text-field v-model="formData.code" :rules="formRules.code" readonly disabled dense outlined label="Code" required></v-text-field>
                    <v-text-field v-model="formData.name" :rules="formRules.name" dense outlined label="Name" required></v-text-field>
                  </v-col>
                  <v-col lg="6">
                    <v-autocomplete :items="types" v-model="formData.type" :rules="formRules.type" dense outlined label="Type" required></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="saveData()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="goBack()" class="mx-1">Back</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: null,
      formData: {
        id: "",
        code: "",
        name: "",
        type: "",
      },
      formRules: {
        id: [(v) => !!v || "ID is required"],
        code: [(v) => !!v || "Code is required", (v) => (v && v.length <= 20) || "Max 20 characters"],
        name: [(v) => !!v || "Name is required", (v) => (v && v.length <= 50) || "Max 50 characters"],
        type: [(v) => !!v || "Type is required"],
      },
      types: ["-"], // v-autocomplete
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async saveData() {
      this.validate();
      var d = this.$refs.form.validate();
      if (d == true) {
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.formData.id);
        formData.append("code", this.formData.code);
        formData.append("name", this.formData.name);
        formData.append("type", this.formData.type);
        await this.$axios
          .post("cparsource/update", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              if (resData.status == "success") {
                this.showAlert(resData.status, resData.message);
                this.resetValidation();
                
                this.goBack();
              } else {
                this.showAlert(resData.status, resData.message);
              }
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
    },

    async getDetail(refresh) {
      this.loading = true;

      if (refresh == true) this.AxiosStorageRemove("GET", "cparsource/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.$route.query.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cparsource/detail", config)
        .then((res) => {
          this.formData.id = res.data.data.id;
          this.formData.code = res.data.data.code;
          this.formData.name = res.data.data.name;
          this.formData.type = res.data.data.type;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async getDocumentTypes(refresh) {
      this.loading = true;

      this.types = [];

      if (refresh == true) this.AxiosStorageRemove("GET", "public/cpar-document-types");

      var config = {
        params: {
          id: this.$route.query.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("public/cpar-document-types", config)
        .then((res) => {
          this.types = res.data.data;
        })
        .catch((error) => {
          this.loading = error;
          this.types = [];
        });
    },
  },

  mounted() {
    this.resetValidation();
    this.getDetail(true);
    this.getDocumentTypes(true);
    this.modulePermission("cparsource", "modify", true);
  },
};
</script>
